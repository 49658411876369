const theme = {
  colors: {
    black: "#000000",
    white: "#FFFFFF",
    orange: "#FF871F",
    grey1: "#EEE8E4",
    grey2: "#C2AFA8",
    blue1: "#4DBDC6",
    blue2: "#92DFE1",
    blue3: "#1E15C1",
  },
  spacing: {
    small: "8px",
    medium: "16px",
    large: "24px",
  },
}

export default theme
