import React from "react"
import styled from "styled-components"
import Container from "../primitives/container"
import theme from "../../config/theme"
import Mail from "../icons/mail"
import Call from "../icons/call"
import instagram from "../../images/instagram.png"
import facebook from "../../images/facebook.png"
import Tripadvisor from "../constructs/tripadvisor"

const StyledLink = styled.a`
  color: black;
  text-decoration: none;
`

const StyledContainer = styled(Container)`
  background: ${theme.colors && theme.colors.blue2};
  align-items: center;
  justify-content: center;
  padding-top: 10px;
`

const StyledDiv = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`

const Line = styled.div`
  background: ${theme.colors && theme.colors.blue3};
  height: 1px;
  width: 80%;
  margin-top: 4px;
  margin-bottom: 8px;
`

const Footer = () => (
  <StyledContainer>
    <Tripadvisor />
    <div />
    <StyledDiv style={{ paddingTop: "8px", paddingBottom: "4px" }}>
      <a href="https://www.instagram.com/seacabskopelos/">
        <img alt="instagram logo" src={instagram} style={{ marginRight: "16px" }} />
      </a>
      <a href="https://www.facebook.com/SeaCab.gr">
        <img alt="facebook logo" src={facebook} />
      </a>
    </StyledDiv>
    <Line />
    <StyledLink
      href="mailto:enquiries@seacab.gr?Subject=Make%20booking"
      target="_top"
    >
      <StyledDiv>
        <Mail style={{ width: "14", marginRight: "8px" }} />
        enquiries@seacab.gr
      </StyledDiv>
    </StyledLink>
  <StyledLink
      href="tel:+306984203695"
      target="_top"
    >
      <StyledDiv>
        <Call style={{ width: "14", marginRight: "8px" }} />
        +30 698 4203695
      </StyledDiv>
    </StyledLink>
    <StyledDiv style={{ marginTop: "4px" }}>© 2019 SeaCab</StyledDiv>
  </StyledContainer>
)

export default Footer
