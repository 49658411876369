import React from "react"
import SEO from "../seo"
import LogoSection from "../sections/logo-section"
import Footer from "../sections/footer"

const Layout = ({ children, menu, title, description }) => (
  <div style={{ maxWidth: "960px", margin: "0 auto" }}>
    <SEO title={title} description={description} />
    <LogoSection menu={menu} />
    {children}
    <Footer />
  </div>
)

export default Layout
