import styled from "styled-components"
import theme from "../../config/theme"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ bgColor }) =>
    theme.colors && ((bgColor && theme.colors[bgColor]) || "white")};
`

export default Container
