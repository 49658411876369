import React from "react"
import styled from "styled-components"
import { Link } from "@reach/router"
import logo from "../../images/logo_blue_orange2.svg"
import Menu from "../constructs/simple-menu"

const StyledImg = styled.img`
  width: 120px;
  margin-left: 1rem;
  @media only screen and (min-width: 600px) {
    width: 180px;
  }
`

const StyledContainer = styled.div`
  padding-bottom: 4px;
  height: 50px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  max-width: 960px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  @media only screen and (min-width: 600px) {
    height: 70px;
  }
`

const LogoSection = ({ menu, theme }) => (
  <StyledContainer theme={theme}>
    <Link to="/" style={{ position: "relative" }}>
      <StyledImg
        src={logo}
        alt="SeaCab: water-taxi transfers from Skiathos to Skopelos"
      />
    </Link>
    {menu && <Menu />}
  </StyledContainer>
)

export default LogoSection
