import React from "react"
import Button from "@material-ui/core/Button"
import MenuIcon from "@material-ui/icons/Menu"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { Link as ScrollLink } from "react-scroll"
import { Link } from "@reach/router"

export default function SimpleMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ padding: "0" }}
      >
        <MenuIcon style={{ fontSize: "36px" }} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <Link to="/enquire">Enquire</Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ScrollLink
            to="prices"
            spy={true}
            duration={500}
            smooth={true}
            offset={-74}
          >
            Prices
          </ScrollLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ScrollLink
            to="where"
            spy={true}
            duration={500}
            smooth={true}
            offset={-74}
          >
            Where's the boat?
          </ScrollLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ScrollLink
            to="faqs"
            spy={true}
            duration={500}
            smooth={true}
            offset={-74}
          >
            FAQs
          </ScrollLink>
        </MenuItem>
      </Menu>
    </>
  )
}
