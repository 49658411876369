import React from "react"
import styled from "styled-components"

const StyledDiv = styled.div`
  div {
    background: none;
    border: none;
  }
`

const Tripadvisor = () => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href="https://www.tripadvisor.com/Attraction_Review-g189501-d17821239-Reviews-SeaCab-Skopelos_Sporades.html"
  >
    <StyledDiv>
      <div id="TA_cdsratingsonlynarrow350" class="TA_cdsratingsonlynarrow">
        <ul id="80bNGet6b" class="TA_links mE4BAE">
          <li id="u9nO7YH4pF" class="aWtKBjB9S">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.tripadvisor.com/Attraction_Review-g189501-d17821239-Reviews-SeaCab-Skopelos_Sporades.html"
            >
              <img
                src="https://www.tripadvisor.com/img/cdsi/img2/branding/tripadvisor_logo_transp_340x80-18034-2.png"
                alt="TripAdvisor SeaCab"
              />
            </a>
          </li>
        </ul>
      </div>
    </StyledDiv>
  </a>
)

export default Tripadvisor
